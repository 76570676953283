import React from 'react';
import Navbar from './navbar';

const ThankYou = () => {
    return (
        <div className='w-full'>
            <Navbar bg={1} />
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full text-center">
                    <h1 className="text-4xl font-bold text-secondary">Thank You!</h1>
                    <p className="mt-4 text-lg text-gray-600">
                        Booking has been submitted, one of our agents will call you shortly.
                        For quick response, please call: (020-3915-4952)
                    </p>
                    <div className="mt-6">
                        <button
                            className="px-6 py-3 bg-secondary text-white rounded-md hover:bg-black transition duration-300"
                            onClick={() => {
                                localStorage.clear();
                                window.location.href = '/';

                            }}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ThankYou;
