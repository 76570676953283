import React from 'react';

import Footer from '../../components/footer';
import TopSection from '../../components/TermandConditions/TopSection';
import ContactForm from '../../components/TermandConditions/TermAndCondtions';


import BannerSection from '../../components/Home3/BannerSection';
import Navbar from '../../components/navbar';



const TandCIndex=({headng,data})=>{

    return(
        <div>
            <Navbar bg={0}/>
            <BannerSection/>
            <div className='max-md:p-3'>
            <TopSection headng={headng}/>
            <ContactForm headng={headng} data={data}/>
            
            </div>
            <Footer/>
        </div>
    )
}
export default TandCIndex