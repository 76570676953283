import React, { useEffect, useState } from 'react';
import { Phone } from 'react-feather';
import whatsapp from "../../assets/images/icons/whatsapp.png";



export default function Popup() {

  const [open, setOpen] = useState(false);

  useEffect(() => {

    document.getElementById('my_modal_popup1').showModal()
    setOpen(true);

  }, [])

  useEffect(() => {
    if (!open) {
      setTimeout(() => {

        document.getElementById('my_modal_popup1').showModal()
        setOpen(true);

      }, 60000)
    }

  }, [open])
  return <div>
    <dialog id="my_modal_popup1" className="modal">
      <div className="modal-box w-[60vw] md:w-[35vw]  bg-white rounded-lg ">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-sm btn-circle  absolute right-2 top-2 border-none" onClick={() => { setOpen(false) }}>✕</button>
        </form>
        <div className='flex flex-col items-center justify-center gap-2 my-5'>
          <p className='text-xl md:text-2xl font-bold mt-1'>Save upto 30% off</p>
          <p className='text-md  font-semibold text-mDarkGray'>Make a Quick Call to our experts on:</p>
          <div className="flex gap-5 justify-center items-center">
            <Phone className="" role="button" onClick={() => { window.location.href = 'tel:02039154952' }} />
            <img src={whatsapp} className="h-[30px] w-[30px]" role="button" onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=447365892753&text=`, '_blank'); }} />

          </div>

          <p className='text-md  font-semibold text-mDarkGray'>Save Big on Family & Group Bookings</p>

        </div>


      </div>
    </dialog>
  </div>
}