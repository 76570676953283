import React, { useEffect, useState } from "react";
import service from "../../Service/Service";
import MyInput from "../Input/MyInput";
import { ThumbsUp } from "react-feather";
import Select from "../Home3/SelectAsync";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const InputSectionUmrah = ({ setShowToast, selectedPackage, pkgtype }) => {
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');
  const [departure_name, setDepartureName] = useState('');
  const [arrival_name, setArrivalName] = useState('');
  const [date, setDate] = useState('');
  const [return_date, setReturnDate] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [accomodation, setAccomodation] = useState('');
  const [makkah_nights, setMakkahNights] = useState(1);
  const [madinah_nights, setMadinahNights] = useState(1);
  const [nights, setNights] = useState(1);
  const [rooms, setRooms] = useState('');
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [description, setDescription] = useState('');
  const [travelerSelect, setTravlerSelect] = useState(false);

  const [categories, setCategories] = useState([])
  const [packages, setPackages] = useState([])
  const [airportDep, setAirportDep] = useState([])
  const [airportArv, setAirportArv] = useState([])
  const [toast, showToast] = useState(false)
  const [error, setError] = useState('')
  console.log(description, selectedPackage);

  const navigate = useNavigate()

  const getAllAirports = () => {
    service.getAllAirports().then(response => {
      let dep = response.data.filter(x => x.type == 'DEPARTURE')
      let arv = response.data.filter(x => x.type == 'ARRIVAL')

      setAirportDep(response.data);
      setAirportArv(response.data)
    })
  }

  useEffect(() => {

    getAllAirports();
    reset();
  }, [])

  const submit = () => {
    if (!departure && pkgtype == 'holiday') {
      setError('Please Select Departure');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!arrival && pkgtype == 'holiday') {
      setError('Please Select Arrival');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }

    if (departure == arrival && pkgtype == 'holiday') {
      setError('Departure and Arrival must be different');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!date && pkgtype == 'holiday') {
      setError('Please Select Date');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!name) {
      setError('Please Enter Full Name');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!phone) {
      setError('Please Enter Phone');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!email) {
      setError('Please Enter Email');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }



    let params = {
      departure,
      arrival,
      date,
      full_name: name,
      phone,
      adult: adults,
      children,
      infants,
      status: 'NEW',
      reviews: '',
      description: description,
      return_date,
      departure_name,
      arrival_name,
      email,
      nights,
      booking_site: 'aittravels.uk'
    }

    if (selectedPackage) {
      params.package_id = selectedPackage.uid;
      params.booking_type = 'Umrah';
    } else {
      params.package_id = -2;
      params.booking_type = 'Umrah';
    }

    service.addBooking(params).then(x => {
      if (selectedPackage && selectedPackage.uid) {
        // document.getElementById('my_modal_4').showModal()
        // if (setShowToast) {
        //   setShowToast(true);
        // }
        window.location.href = '/thankyou'
        reset()
      } else {
        //   localStorage.setItem('booking',JSON.stringify(params))
        //   localStorage.setItem('bookingresult',JSON.stringify(x.data))
        //  window.location.href=`/flight-detail?package=0&call=${new Date().getTime()}`


        //var scrollDiv = document.getElementById("catsec").offsetTop;
        //window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        window.location.href = '/thankyou'
        reset()
      }



    }).catch(err => {
      console.log(err);
    })
  }

  const reset = () => {
    setDeparture('')
    setDate('')
    setName('')
    setNights('')
    setDepartureName('')
    setAdults(0)
    setChildren(0)
    setInfants(0)
    setArrival('')
    setArrivalName('')
    setPhone('')
    setEmail('')
  }

  return (
    <div className="w-full mt-2">

      {pkgtype == 'holiday' && <div className="grid grid-cols-2 md:grid-cols-2 gap-1">
        {
          toast && <div className="absolute z-[100]">
            <div className="toast toast-top toast-end">
              <div className="alert alert-info bg-[red]">
                <span className="text-white text-lg">{error}</span>
              </div>
            </div>


          </div>
        }
        <div className="p-1 flex flex-col col-span-2 md:col-span-1">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Departure Airport</label>
          <Select

            onChange={(e) => {
              setDeparture(e.value)
              setDepartureName(e.label)
            }} />
          {/* <select className="p-2 w-full max-w-xs rounded-sm" onChange={(e)=>{setDeparture(e.target.value)}}>
  
    <option disabled selected>Pick your Departure</option>
    {
      airportDep.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
    
  </select> */}
        </div>
        <div className="p-1 flex flex-col col-span-2 md:col-span-1">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Arrival Airport</label>
          <Select onChange={(e) => {
            setArrival(e.value)
            setArrivalName(e.label)
          }} />
          {/* <select className="p-2 w-full max-w-xs rounded-sm" onChange={(e)=>{setArrival(e.target.value)}}>
    <option disabled selected>Pick your Arrival</option>
    {
      airportArv.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
  </select> */}
        </div>
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Departure Date</label>
          <input type="date" placeholder="Type here" className="p-2 w-full  h-[50px] rounded-lg" value={date} onChange={(e) => { setDate(e.target.value) }} />
        </div>
        {/* <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Return Date</label>
          <input type="date" placeholder="Type here" className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e)=>{setReturnDate(e.target.value)}}/>
        </div> */}
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Nights</label>
          <select className="p-2 w-full  h-[50px] rounded-lg" value={nights} onChange={(e) => { setNights(e.target.value) }}>
            <option disabled selected>Nights</option>
            {
              Array(30).fill(null).map((_, i) => {
                return <option value={i + 1}>{i + 1}</option>


              })
            }


          </select>
        </div>








      </div>}


      <div className={pkgtype == 'holiday' ? "grid grid-cols-2 md:grid-cols-3" : "grid grid-cols-2 md:grid-cols-2"}>
        {pkgtype == 'umrah' ? '' : <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Adult</label>
          <select className="p-2 w-full  h-[50px] rounded-lg" value={adults} onChange={(e) => { setAdults(e.target.value) }}>
            <option disabled selected>Adults</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>

        </div>}

        {pkgtype == 'holiday' ? <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Child</label>
          <select className="p-2 w-full  h-[50px] rounded-lg" value={children} onChange={(e) => { setChildren(e.target.value) }}>
            <option disabled selected>Child</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>
        </div> : ''}

        {pkgtype == 'holiday' ? <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Infants</label>
          <select className="p-2 w-full  h-[50px] rounded-lg" value={infants} onChange={(e) => { setInfants(e.target.value) }}>
            <option disabled selected>Infants</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>
        </div> : ''}

        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Full Name</label>
          <input type="text" placeholder="Type here" className="p-2 w-full  h-[50px] rounded-lg" value={name} onChange={(e) => { setName(e.target.value) }} />
        </div>
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Phone Number</label>
          <input type="Number" placeholder="Type here" className="p-2 w-full  h-[50px] rounded-lg" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
        </div>
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Email</label>
          <input type="text" placeholder="Email" className="p-2 w-full  h-[50px] rounded-lg" value={email} onChange={(e) => { setEmail(e.target.value) }} />
        </div>

        {
          pkgtype == 'umrah' ? <div className="p-1 flex flex-col">
            <label className="tracking-[1px] font-[500] leading-6 text-white"> No of Paggengers</label>
            <select className="p-2 w-full  h-[50px] rounded-lg" value={adults} onChange={(e) => { setAdults(e.target.value) }}>
              <option disabled selected>Passengers</option>
              <option value={`0`}>0</option>
              <option value={`1`}>1</option>
              <option value={`2`}>2</option>
              <option value={`3`}>3</option>
              <option value={`4`}>4</option>
              <option value={`5`}>5</option>
            </select>

          </div> : ''
        }

      </div>



      {/* <div className="flex my-2 gap-2">
        <input type="checkbox"/>
        <p className="text-white text-md"> By proceeding, I acknowledge that I have read and agreed to Air Travels UK Terms And Conditions and Privacy Policy statements.</p>
      </div> */}

      {
        selectedPackage && selectedPackage.uid ? <div className="p-1">
          <button className="btn border-[#22c55e] rounded-xl bg-[#22c55e] w-full my-5" onClick={submit}>Submit</button>
        </div> : <div className="p-1">
          <button className="btn border-secondary rounded-xl bg-secondary w-full my-5 text-white" onClick={submit}>Search</button>
        </div>
      }
      <div id='scrollbt'></div>

      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-secondary">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={() => { window.location.reload() }}>✕</button>
          </form>
          <h5 className="text-white">Booking has been submitted, one of our agents will call you shortly.
            For quick response, please call: (020-3838-0380)</h5>
        </div>
      </dialog>
    </div>

  )
}



export default InputSectionUmrah