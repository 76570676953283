import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions = props => {

    const data = [
        {
            heading: ``,
            paras: [`This privacy policy notice is for the website www.airfares4uk.co.uk. The purpose of this policy is to explain to you how we control, process, handle and protect your personal information while browsing or using this website, including your rights under current laws and regulations. If you do not agree to the following policy you may wish to cease viewing / using this website`],
            para: ``
        },
        {
            heading: `Policy key Definitions`,
            paras: [
                `1. "I", "our", "us", or "we" refer to the business, Air Travels UK or any of its subsidiary trading names`,
                `2. You", ""your", the user" refer to the person(s) using this website.`,
                `3. GDPR means General Data Protection Act.`,
                `4. PECR means Privacy & Electronic Communications Regulation.`,
                `5. ICO means Information Commissioner's Office.`,
                `6. Cookies mean small files stored on a user’s computer or device`
            ],
            para: ``
        },
        {
            heading: `Processing of Your Personal Data`,
            paras: [
                `Under the GDPR (General Data Protection Regulation) we control and / or process any personal information about you electronically using the following lawful bases.`,
                `If, as determined by us, the lawful basis upon which we process your personal information changes, we will notify you about the change and any new lawful basis to be used if required. We shall stop processing your personal information if the lawful basis used is no longer relevant.`
            ],
            para: ``
        },
        {
            heading: `Your individual Rights`,
            paras: [
                `Under the GDPR your rights are as follows. You can read more about your rights in details here;`,
                `1. The right to be informed;`,
                `2. The right of access;`,
                `3. The right to rectification;`,
                `4. The right to erasure;`,
                `5. The right to restrict processing;`,
                `6. The right to data portability;`,
                `7. The right to object; and`,
                `8. The right not to be subject to automated decision-making including profiling.`,
                `You also have the right to complain to the ICO [www.ico.org.uk] if you feel there is a problem with the way we are handling your data.`,
                `We handle subject access requests in accordance with the GDPR.`
            ],
            para: ``
        },
        {
            heading: `Personal Information That We Collect`,
            paras: [
                `Personal information is information that relates to an identifiable natural person. An identifiable natural person is someone that can be identified both directly and indirectly by their name, location data, and identification number or by factors specific to the social, cultural, physiological, genetic, mental or economic identity of the natural person. Personal Information does not include any information that has been anonymized so that it can no longer be used to identify a natural person.`,
                `When you use our travel services, we do collect some personal information from you and any devices you use. Personal identifying information can include your name, telephone numbers, address, email addresses and financial information such as card details. Provision of any other personal information is voluntary on your account, but it may become necessary in order to use some of our services.`,
                `We automatically collect information about your interaction with our website and communications with us. This is the information that we receive via devices you use when you access our website. This information may include a Device ID or unique identifier, a unique device token, a device type or an ID for advertising. We may also receive location information from your mobile device, but most mobile devices now allow you to disable the use of your location settings on internet browsers. We also collect computer and connection information such as your page view statistics, referral URL, browsing history, IP address and web log information.`,
                `We use cookies, unique identifiers, web beacons and other similar technologies to collect personal information about pages that you view, links that you clink on and other actions you may take whilst using our website and services.`
            ],
            para: ``
        },
        {
            heading: `Personal Information We Collect From Other Sources`,
            paras: [`We add on the personal information we collect with other information collected from third parties, and implement it to your account information. For example, we collect and use demographic information that is available in credit check information, additional contact information, information from credit bureaus and an applicable jurisdiction, which is allowed by some applicable national laws.`],
            para: ``
        },
        {
            heading: `Social Media`,
            paras: [
                `We allow you to share pages and information from our website on social media sites, and these social media sites may give us automatic access to personal information they have retained from you, for example, content liked by you or viewed by you, and information about advertisements that you may have been shown or clicked on.`,
                `If you allow us access to websites with video content, then you agree that we can share your video viewing information from social media sites for at least 2 years, unless you withdraw any connections or authorization of the social media sites.`,
                `You are in control of the personal information that you allow us to have access to on the privacy settings included on social media sites, and also by the permission you give us when you allow us access to this personal information that is retained by the social media site about you.`,
                `By associating your account with an account managed by social media, and by authorizing us access to this personal information, you agree that we can collect, use and keep the information we receive from these social media sites in accordance with this privacy policy. We may use other technologies from social media sites, as well as plug ins. For instance if you click on a link via a plug in on social media, you will be establishing a connection with that social media site.`,
                `If you provide us with any personal information about someone else, you can only do so with that person’s authorization. You should inform the individual about how we collect, use, keep and disclose their personal information according to this privacy policy.`
            ],
            para: ``
        },
        {
            heading: `How We Use the Personal Information We Collect From You`,
            paras: [
                `We use your personal information for many business purposes and for different legal reasons. Below is a summary of how we may use your information and for which legal bases.`,
                `1. To provide you with our travel services and enable servicing of the travel booking`,
                `2. To fulfil a binding contract with you or to comply with our legal obligations`,
                `3. To provide account management and payment processing`,
                `4. To improve, operate and measure our services`,
                `5. To keep our services safe and secure`,
                `6. To customize any site content`,
                `7. To contact you regarding your account`,
                `8. To resolve any disputes`,
                `9. To troubleshoot any problems associated with your account`,
                `10. To collect any dues`
            ],
            para: ` `
        },
        {
            heading: `If we contact you for any purposes outlined above, it will be via telephone, email, SMS or post.`,
            paras: [
                `We will use location information to provide location based services (i.e. search results and advertising)`,
                `We may also use your personal information to detect, prevent, alleviate and investigate security breaches, fraud and other illegal or prohibited activities`,
                `Personal information is used to pursue some of our legitimate interests (your rights and freedom does not outweigh these interests). We have controls in place to balance your rights with our interests.`
            ],
            para: ` `
        },
        {
            heading: `Some of our legitimate interests include`,
            paras: [
                `Improving our services, for example reviewing any information which is associated with crashed or stalled site pages experienced by users, allowing us to fix any issues and ensure you have a better online experience with us.`,
                `Personalizing and improving our advertising methods based on our preferences on advertising.`,
                `Contacting you with discount offers and special promotions or surveys as authorized by law.`,
                `Contacting you about any current or public policy events which are related to your ability to use our website and services (this may include letter writing or other public campaigns related to public policy).`,
                `Delivering specifically targeted marketing, promotional deals and service updates that are based on your communication preferences.`,
                `Analyzing our open and click rates on email marketing campaigns such as newsletters.`,
                `Monitoring and improving the data and information security of our website`,
                `With your consent, we may also use your personal information to provide you with marketing via emails, SMS and telephone calls, provide you with marketing from other third parties, customize any third party advertising that may be visible to you on third party websites, user your geo-location to provide you with location based services and use any sensitive personal information to make transactions smoother.`,
                `Please note you have the right to withdraw your consent at any time.`,
            ],
            para: ` `
        },
        {
            heading: `You’re choices about how we can use your personal information`,
            paras: [],
            para: ` `
        },
        {
            heading: `Marketing`,
            paras: [
                `If you no longer want to receive any marketing communications from us, you have the option to unsubscribe via the link at the bottom of any emails you receive. From there you can change your communication preferences.`,
                `We do not sell or disclose any personal information to third parties for their own marketing purposes without asking for your consent.`
            ],
            para: ` `
        },
        {
            heading: `Advertising`,
            paras: [
                `If you no longer want to participate in our advertising programs, you can opt-out by following the directions enclosed with the advertisement, or via the programs which are noted in our user cookie notice. After you-opt out, personalized advertising will stop, but it will still allow your personal information to be collected, as described in this privacy policy.`,
                `We do not allow any third parties to collect or track personal information from our sites for their own advertising purposes without content.`
            ],
            para: ` `
        },
        {
            heading: `Ways You Can Access, Control and Correct Your Personal Information`,
            paras: [
                `Air Travels UK UK will honor any rights you may have to access, change or delete your personal information stored by us. To request access and to find out if any fees apply, if permitted by applicable laws, please click on the contact us page below and follow the necessary instructions. In cases where you have a right to request access or request the modification or deletion of any personal information, we can withhold the access or decline to change or delete your personal information in certain cases, in accordance with applicable laws.`,
                `If you request us to stop processing your personal information, or you withdraw your consent for our use to disclose any personal information for any purposes outlined in this privacy policy, we may not be able to provide you with all of the customer service and support offered to our users.`
            ],
            para: ` `
        },
        {
            heading: `How We Might Share Your Personal Information`,
            paras: [
                `We may disclose personal information to financial institution partners, third party service providers, law enforcement or government agencies and credit agencies or bureaus authorized by law for the following purposes:`,
                `• To provide joint services and content (i.e. customer support and transactions)`,
                `• To help us detect, prevent and alleviate any potential fraud or illegal acts and violations of our data security`,
                `• To send you personalized advertising and marketing communications`,
                `• To improve products, services, tools and marketing`,
                `• To help us provide our customer services and payment processing services`,
                `• To offer you financial products`,
                `• To comply with our legal requirements`,
                `• To respond to claims that any content violates the rights of others`,
                `• To protect an individual’s rights and safety`,
                `• In response to legal process relating to criminal investigations or alleged illegal activity (we only disclose information deemed relevant to the investigation with details such as your name, address, telephone number, user ID history, IP address, email address and any fraud complaints)`,
                `• For information on missed or late payments which may be implemented onto your credit report`,
                `• In order to prevent any financial loss or imminent physical harm`,
                `• To report any suspected illegal activity`,
            ],
            para: ` `
        },
        {
            heading: `Change of Ownership`,
            paras: [
                `In the unlikely event that we are subject to merge with another company or change of ownership, we may share information with them (in accordance with international privacy standards). We will require that the new organization will follow this privacy notice to respect any personal information given. If we handle your personal information for any purposes not included in this policy, you will receive a notification prior to the processing of this information for the new purposes.`
            ],
            para: ` `
        },
        {
            heading: `How Long We Keep Your Personal Information`,
            paras: [],
            para: ` `
        },
        {
            heading: `Advertising`,
            paras: [
                `How long we keep any personal information can vary depending on the context of the service we provide, and also on our legal obligations. The below factors influence the period in which your personal information is retained:`,
                `• How long the personal information is needed to provide our services`,
                `• Improving and maintaining the performance of our products`,
                `• Keeping our website and systems secure`,
                `• Maintaining any appropriate financial and business records`,
                `• A shortened data retention time will apply for sensitive personal information`,
                `• If you have provided consent for a longer period`,
                `• We are subject to contractual or legal obligations to retain personal information, for example mandatory data retention laws and government orders to preserve data which is relevant to any investigations`,
                `• Personal information may be retained for litigation purposes`,
                `The above are general factors that establish a baseline for our data retention.`,
                `After these exceptions, it will be no longer necessary for us to keep your personal information. We will dispose of the data securely according to our deletion and data policies.`
            ],
            para: ` `
        },
        {
            heading: `Internet cookies`,
            paras: [
                `We use cookies on this website to provide you with a better user experience. We do this by placing a small text file on your device / computer hard drive to track how you use the website, to record or log whether you have seen particular messages that we display, to keep you logged into the website where applicable, to display relevant adverts or content, referred you to a third party website.`,
                `Some cookies are required to enjoy and use the full functionality of this website.`,
                `We use a cookie control system which allows you to accept the use of cookies, and control which cookies are saved to your device / computer. Some cookies will be saved for specific time periods, where others may last indefinitely. Your web browser should provide you with the controls to manage and delete cookies from your device, please see your web browser options.`,
                `If you have any questions regarding this policy, or you wish to update your details, please inform us using the Contact us facility on our Site. Thank you for visiting the Air Travels UK UK`
            ],
            para: ` `
        },
    ]

    return (

        <div className='relative mt-3  p-10'>
            {
                data.map(x => {
                    return <div className="my-6">
                        <p className=' text-2xl tracking-[1px] leading-10'>
                            {x.heading}
                        </p>
                        {
                            x.paras.map(e => {
                                console.log(e);
                                return <p className=' text-md mb-3 tracking-[2px] leading-7'>
                                    {e}
                                </p>
                            })
                        }

                    </div>
                })
            }

        </div>
    )
}
export default TermandConditions